<template>
  <div class="related">
    <div v-if="relatList.length>0">
      <van-list finished-text="没有更多了">
        <div class="orderItem" v-for="iitm in relatList" :key="iitm.id">
          <div class="item_top g-flex-jb g-flex-ac">
            <div
              class="ml-30 item_tit_le fs-32"
              style="color: #FDC95B;"
              v-if="iitm.exa_status===0"
            >待审核</div>
            <div
              class="ml-30 item_tit_le fs-32"
              style="color: #444CF0;"
              v-else-if="iitm.exa_status===1"
            >审核通过</div>
            <div
              class="ml-30 item_tit_le fs-32"
              style="color: #FB7320;"
              v-else-if="iitm.exa_status===2"
            >失败</div>
            <div
              class="ml-30 item_tit_le fs-32"
              style="color: #666666;"
              v-else-if="iitm.exa_status===3"
            >完成</div>
            <div class="ml-30 item_tit_le fs-32" style="color: #666666;" v-else>完成</div>
            <div class="item_tit_ri fs-28" v-if="iitm.order_type===1">权益单</div>
            <div class="item_tit_ri fs-28" v-else>理赔单</div>
          </div>
          <div class="mt-30 g-flex-ac">
            <div class="ml-30 fs-28 left_wid" style="color:#999">权益类型:</div>
            <div class="fs-28" style="color:#333">{{iitm.instype_name}}</div>
          </div>
          <div class="mt-30 g-flex-ac">
            <div class="ml-30 fs-28 left_wid" style="color:#999">参保人:</div>
            <div class="fs-28" style="color:#333">{{iitm.name}}</div>
          </div>
          <div class="mt-30 g-flex-ac">
            <div class="ml-30 fs-28 left_wid" style="color:#999">生效期限:</div>
            <div
              class="fs-28"
              style="color:#333"
              v-if="iitm.term_time_start&&iitm.term_time_end"
            >{{iitm.term_time_start}}-{{iitm.term_time_end}}</div>
          </div>
          <div class="mt-30 g-flex-ac">
            <div class="ml-30 fs-28 left_wid" style="color:#999">接待人员:</div>
            <div class="fs-28" style="color:#333">{{iitm.shop_member_name}}</div>
          </div>
          <div class="bot_img g-flex-dft">
            <div class="img_left">
              <img :src="iitm.tem_img" alt />
            </div>
            <div>
              <div class="img_right_t fs-28 mt-20 ml-20">旧机型号:{{iitm.model_name}}</div>
              <div class="img_right_t fs-28 mt-20 ml-20">新机型号:{{iitm.new_model_name}}</div>
              <div
                class="img_right_bot fs-24 ml-20"
                v-if="iitm.ins_service_name"
              >{{iitm.ins_service_name}}</div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
export default {
  name: "related",
  data() {
    return {
      relatList: []
    };
  },
  mounted() {
    this.relatList = JSON.parse(sessionStorage.getItem("relatData"));
    console.log(this.relatList, "123");
  },
  methods: {}
};
</script>

<style lang="less" scoped>
.related {
  padding: 10px 0 30px 0;
  .orderItem {
    margin: 30px auto;
    width: calc(100vw - 60px);
    padding-bottom: 5px;
    background: #ffffff;
    box-shadow: 0px 4px 21px 9px rgba(196, 197, 197, 0.4);
    border-radius: 20px;
    .bot_img {
      width: calc(100vw - 80px);
      margin: 20px auto;
      height: 150px;
      background: #f6f8fe;
      border-radius: 16px;
      .img_left {
        width: 126px;
        height: 130px;
        img {
          margin-top: 10px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .img_right {
        height: 27px;
        color: #333333;
      }
      .img_right_bot {
        margin-top: 20px;
        width: 110px;
        height: 32px;
        background: #8eaee5;
        border-radius: 6px;
        color: white;
        text-align: center;
        line-height: 32px;
      }
      .img_right_t {
        width: 251px;
        height: 27px;
        font-size: 28px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
    .left_wid {
      width: 150px;
    }
    .item_top {
      height: 70px;
      width: 100%;
      border-bottom: 1px solid #f9f9f9;
      .item_tit_le {
        width: 180px;
        height: 72px;
        line-height: 72px;
      }
      .item_tit_ri {
        width: 148px;
        height: 72px;
        background: linear-gradient(
          -90deg,
          #e1f0fe 0%,
          rgba(225, 240, 254, 0) 100%
        );
        border-radius: 0px 20px 0px 0px;
        border-radius: 0px 20px 0px 0px;
        color: #0080ff;
        text-align: center;
        line-height: 72px;
      }
    }
  }
}
</style>
